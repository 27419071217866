import React from "react";
import styled from "styled-components";

import { Link as LinkType } from "./nav-config";
import { NavLink } from "./NavLinks";

export const gridAreaName = "secondLevel";
const NavBar = styled.div`
  align-content: start;
  grid-area: ${gridAreaName};
  overflow-y: auto;
  height: 100%;
  margin: auto 0px;
  display: grid;

  width: 100%;
  gap: 8px;
  ${(p) => p.theme.breakpoints.up("lg")} {
    width: fit-content;
    grid-auto-flow: column;

    gap: 24px;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.xl}) {
    gap: 36px;
  }
`;

type Props = {
  links: LinkType[];
  showMenu: boolean;
};

export const SecondLevelNav = ({ links, showMenu, ...props }: Props) => {
  return (
    <NavBar {...props}>
      {links.map((link) => (
        <NavLink key={link.link} link={link} tabable={showMenu} largeOnMobile />
      ))}
    </NavBar>
  );
};
