/* eslint-disable babel/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import * as React from "react";
import styled from "styled-components";

import {
  ActionBar,
  ActionBarProps as ActionBarPropsBase,
} from "@origin-digital/ods-action-bar";

import {
  AlertBanner,
  AlertBannerProps,
  Hidden,
  MarkdownLite,
  Text,
} from "@origin-digital/ods-core";

import {
  eventListener,
  IActionBarUpdate,
  IAlertBanner,
  IAlertBannerClose,
  IOriginEvents,
  ISubscription,
  navigationBack,
  navigationClose,
  TOPICS,
} from "@origin-digital/event-dispatcher";
import { Z_INDEX } from "../../consts/style";
import { CrisisBanner } from "../Banner";
import { getBreadcrumbs } from "./utils";

const ACTION_BAR_HEIGHT = 129;

export type AlertStatusLabels =
  | "basic"
  | "error"
  | "info"
  | "success"
  | "warning";

interface ActionBarProps {
  title?: ActionBarPropsBase["title"];
  mobileNavButton?: ActionBarPropsBase["mobileNavButton"];
  component?: ActionBarPropsBase["headingComponent"];
  breadcrumbs?: IActionBarUpdate["payload"]["breadcrumbs"];
  desktopVariant?: ActionBarPropsBase["desktopVariant"];
}

interface AlertBannerContentProps {
  message: string;
  heading?: string;
  type?: AlertStatusLabels;
  uniqueId?: string;
}

export interface HeaderProps extends ActionBarProps {
  hideActionBar?: boolean | "desktop";
  isNative?: boolean;
  enableCrisisBanner?: boolean;
}

interface HeaderState {
  actionBar: ActionBarProps;
  alertBanner?: AlertBannerContentProps;

  actionBarUpdateUnsubscribe?: ISubscription;
  alertBannerOpenUnsubscribe?: ISubscription;
  alertBannerCloseUnsubscribe?: ISubscription;
}

const mapAlertBannerType = (
  oldType?: AlertStatusLabels
): AlertBannerProps["tone"] => {
  if (oldType === "error") {
    return "critical";
  } else if (oldType === "warning") {
    return "caution";
  } else if (oldType === "success") {
    return "positive";
  } else if (oldType === "basic") {
    return "neutral";
  }
  return "info";
};

const HeaderContainer = styled.div<{
  desktopVariant: ActionBarProps["desktopVariant"];
}>`
  z-index: ${Z_INDEX.HEADER};
  position: sticky;
  top: 0;
  ${(p) =>
    p.desktopVariant !== "slim" &&
    `@media only screen and (min-width: ${p.theme.breakpoints.lg}) {
    top: -${ACTION_BAR_HEIGHT}px;
  }`}
`;

const AlertRelativeAnchor = styled.div`
  position: relative;
`;

const AlertContainer = styled.div`
  position: absolute;
  width: 100%;
`;

const BottomLine = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.grey200};
`;

export class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps, state: HeaderState) {
    super(props, state);
    const { title, mobileNavButton, component, breadcrumbs, desktopVariant } =
      props;
    this.state = {
      actionBar: {
        title,
        mobileNavButton,
        component,
        breadcrumbs,
        desktopVariant,
      },
    };
    this.handleOnCloseAlertBanner = this.handleOnCloseAlertBanner.bind(this);
  }

  componentDidMount() {
    const actionBarUpdateUnsubscribe = eventListener.addListener(
      TOPICS.ACTION_BAR_UPDATE,
      (topic: IOriginEvents) => {
        if (topic.topic === TOPICS.ACTION_BAR_UPDATE) {
          this.handleActionBarUpdate(topic);
        }
      }
    );

    const alertBannerOpenUnsubscribe = eventListener.addListener(
      TOPICS.ALERT_BANNER_OPEN,
      (topic: IOriginEvents) => {
        if (topic.topic === TOPICS.ALERT_BANNER_OPEN) {
          this.handleAlertBannerOpen(topic);
        }
      }
    );

    const alertBannerCloseUnsubscribe = eventListener.addListener(
      TOPICS.ALERT_BANNER_CLOSE,
      (topic: IOriginEvents) => {
        if (topic.topic === TOPICS.ALERT_BANNER_CLOSE) {
          this.handleAlertBannerClose(topic);
        }
      }
    );

    this.setState({
      actionBarUpdateUnsubscribe,
      alertBannerOpenUnsubscribe,
      alertBannerCloseUnsubscribe,
    });
  }

  componentWillUnmount() {
    this.state.actionBarUpdateUnsubscribe &&
      this.state.actionBarUpdateUnsubscribe();
    this.state.alertBannerOpenUnsubscribe &&
      this.state.alertBannerOpenUnsubscribe();
    this.state.alertBannerCloseUnsubscribe &&
      this.state.alertBannerCloseUnsubscribe();
  }

  handleActionBarUpdate(topic: IActionBarUpdate) {
    this.setState((prevState) => ({
      actionBar: {
        ...prevState.actionBar,
        ...topic.payload,
      },
    }));
  }

  handleAlertBannerOpen(topic: IAlertBanner) {
    this.setState({ alertBanner: { ...topic.payload } });
  }

  handleAlertBannerClose(topic: IAlertBannerClose) {
    if (
      this.state.alertBanner &&
      this.state.alertBanner.uniqueId === topic.payload.uniqueId
    ) {
      this.handleOnCloseAlertBanner();
    }
  }

  handleOnClickMobileNavButton() {
    if (this.state.actionBar.mobileNavButton === "close") {
      navigationClose();
    } else if (typeof window !== "undefined") {
      navigationBack();
    }
  }

  handleOnCloseAlertBanner() {
    this.setState({ alertBanner: undefined });
  }

  render() {
    const { hideActionBar, isNative } = this.props;
    const { actionBar, alertBanner } = this.state;
    const breakpointToHide = hideActionBar === "desktop" ? "lg" : undefined;
    const showActionBar = hideActionBar === "desktop" ? true : !hideActionBar;
    const breadcrumbs = getBreadcrumbs(actionBar.breadcrumbs);
    const enableCrisisBanner =
      this.props.enableCrisisBanner === undefined
        ? true
        : this.props.enableCrisisBanner;

    return (
      <HeaderContainer desktopVariant={actionBar.desktopVariant}>
        {showActionBar ? (
          <Hidden above={breakpointToHide}>
            <ActionBar
              mobileNavButton={actionBar.mobileNavButton}
              headingComponent={actionBar.component}
              desktopVariant={actionBar.desktopVariant}
              onClickMobileNavButton={() => this.handleOnClickMobileNavButton()}
              title={actionBar.title}
              breadcrumbs={breadcrumbs}
            />

            <BottomLine />
          </Hidden>
        ) : null}
        {isNative && enableCrisisBanner ? <CrisisBanner /> : null}
        {alertBanner ? (
          <AlertRelativeAnchor>
            <AlertContainer>
              <AlertBanner
                title={alertBanner.heading}
                tone={mapAlertBannerType(alertBanner.type)}
                onCloseClick={this.handleOnCloseAlertBanner}
              >
                <Text>
                  <MarkdownLite>{alertBanner.message}</MarkdownLite>
                </Text>
              </AlertBanner>
            </AlertContainer>
          </AlertRelativeAnchor>
        ) : null}
      </HeaderContainer>
    );
  }
}
