import * as React from "react";
import styled from "styled-components";

import { Section, TrackedLink } from "@origin-digital/ods-core";
import { LandscapeLogo } from "../Svg/LandscapeLogo";
import {
  SecondaryNav,
  gridAreaName as secondaryGridArea,
} from "./SecondaryNav";
import { gridAreaName as secondLevelGridArea } from "./SecondLevelNav";
import { PrimaryNav, gridAreaName as firstLevelGridArea } from "./PrimaryNav";
import { HamburgerButton, CloseButton } from "./MobileNavIconButtons";
import { SSRLoginSwitcher } from "./SSRLoginSwitcher";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@origin-digital/ods-types";

import { Nav } from "./nav-config";
import { ButtonLink } from "../Buttons/ButtonLinks";

const SidePadder = styled.div`
  /* ensure that the overflowing background colors don't create
   * any unwanted horizontal scroll */
  overflow-x: hidden;

  /* Tuck in the sides of the children here so that the child component's
    bottom border doesn't extend to the sides */
  padding: 0 16px;
  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    padding: 0 24px;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    padding: 0;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  position: relative;

  display: grid;

  grid-template-areas:
    "leftSection . login"
    "slideInNav slideInNav slideInNav";
  grid-template-rows: 60px;
  grid-template-columns: auto 1fr auto;

  ${(p) => p.theme.breakpoints.up("lg")} {
    border-bottom: none;

    grid-template-areas:
      "leftSection ${firstLevelGridArea} ${secondaryGridArea}"
      "${secondLevelGridArea} ${secondLevelGridArea} login";
    grid-template-rows: 60px 60px;
    grid-template-columns: auto 1fr auto;
  }
`;

const Left = styled.div<{ showMenu: boolean }>`
  grid-area: leftSection;
  display: flex;

  /* Background fill that extends past the grid, all the
   * out to the edge of the page */
  position: relative;
  &:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100vw;
    right: -100vw;

    transition: background 0.3s ease-in-out;
    background: ${(p) =>
      p.showMenu ? p.theme.colors.grey50 : p.theme.colors.white};

    ${(p) => p.theme.breakpoints.up("lg")} {
      background: ${(p) => p.theme.colors.grey50};
    }
  }
`;

export const LogoLink = styled(TrackedLink)`
  margin: auto 60px auto 0;
  & > svg {
    display: block;
    height: 32px;
    width: auto;
  }
`;

export const MobileNavWrapper = styled(SidePadder)<{ showMenu: boolean }>`
  /* On desktop, the items in this nav shouldn't be wrapped in a div,
   * they should flow with the main Container grid. display: contents
   * enables this. */
  display: contents;
  backgroundcolor: white;

  ${(p) => p.theme.breakpoints.down("lg")} {
    position: fixed;
    background-color: white;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    height: calc(100% - 60px);
    top: 60px;
    left: 0;
    right: 0;

    display: grid;
    grid-template-areas:
      "${firstLevelGridArea}"
      "${secondLevelGridArea}"
      "${secondaryGridArea}";
    grid-template-rows: auto 1fr auto;

    /* slide in/out */
    ${(p) =>
      p.showMenu
        ? `
    max-height: 100vh;
    pointer-events: unset;
  `
        : `
    max-height: 0;
    pointer-events: none;
  `}
  }
`;

export const MobileOuterWrapper = styled.div`
  grid-area: slideInNav;
  ${(p) => p.theme.breakpoints.up("lg")} {
    display: contents;
  }
`;

const LoginArea = styled.div`
  grid-area: login;
  margin: auto 0 auto auto;
`;

type MenuBarProps = {
  logoLinkHref: string;
  toggleMenu: () => void;
  showMenu: boolean;
  nav: Nav;
};
export const MenuBar = ({
  logoLinkHref,
  toggleMenu,
  showMenu,
  nav,
}: MenuBarProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  return (
    <SidePadder>
      <Section
        backgroundColor="transparent"
        paddingY="none"
        hideGutter={[true, false]}
        fluidity={[true, false]}
      >
        <Container>
          <Left showMenu={showMenu}>
            {showMenu ? (
              <CloseButton
                onClick={(e) => {
                  toggleMenu();
                  e?.preventDefault();
                }}
                trackingLabel="Close Button"
                data-id="mobile-navigation:close-button"
              />
            ) : (
              <HamburgerButton
                trackingLabel="Hamburger Menu"
                data-id="mobile-navigation:hamburger-menu"
                data-comment="MobileNavButton"
                onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
                  toggleMenu();
                  e?.preventDefault();
                }}
              />
            )}
            <LogoLink
              trackingType="Link"
              trackingLabel="Logo"
              data-id="navigation:logo"
              href={logoLinkHref}
            >
              <LandscapeLogo id="logo" />
            </LogoLink>
          </Left>

          {/* if enterprise is selected, show the enterprise login button */}
          {nav.primaryLinks[2].selected ? (
            <LoginArea>
              <ButtonLink
                href="https://portal.originzero.com.au/login"
                data-id="navigation:origin-zero-log-in"
                trackingType="Link"
                trackingLabel="origin-zero-log-in"
                color="white"
                bgColor="#ec0000"
                hoverColor="#c60000"
              >
                Log in
              </ButtonLink>
            </LoginArea>
          ) : (
            <LoginArea>
              <SSRLoginSwitcher />
            </LoginArea>
          )}

          <MobileNavWrapper showMenu={showMenu}>
            <PrimaryNav
              links={nav.primaryLinks}
              showMenu={showMenu || !isMobile}
            />
            <SecondaryNav
              links={nav.secondaryLinks}
              showMenu={showMenu || !isMobile}
            />
          </MobileNavWrapper>
        </Container>
      </Section>
    </SidePadder>
  );
};
