import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

const paymentHistory_LPG_KRAKEN: IMcAppType = {
  name: "paymentHistory_LPG_KRAKEN",
  path: "/my/bills-payments/lpg/:customerNumber?",
  description: "History of transactions",
  parameters: {
    required: ["customerNumber"],
    properties: { customerNumber: CommonParameters.customerNumber },
  },
  navFlow: NavFlow.STANDARD,
  title: "Bills and Payments",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "paymentHistory",
    customerType: CustomerType.kraken,
    parameters: { customerNumber: "<string>" },
  },
};

const paymentHistory_KRAKEN: IMcAppType = {
  name: "paymentHistory_KRAKEN",
  path: "/my/bills-payments/:accountNumber?",
  description: "History of transactions",
  parameters: {
    required: ["accountNumber"],
    properties: { accountNumber: CommonParameters.accountNumber },
  },
  navFlow: NavFlow.STANDARD,
  title: "Bills and Payments",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "paymentHistory",
    customerType: CustomerType.kraken,
  },
};

// order is important, the LPG conditions are a stronger match so should be matched first
export const paymentHistory = [
  paymentHistory_LPG_KRAKEN,
  paymentHistory_KRAKEN,
];
